<template>
  <div class="page">
    <van-tabs v-model="active">
      <van-tab title="账号间划转">
        <!-- <feng-kong></feng-kong> -->
        <account-trans></account-trans>
      </van-tab>
      <van-tab title="交易对划转">
        <!-- <strategy_api.js></strategy_api.js> -->
        <exchange-trans></exchange-trans>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import AccountTrans from "./com/account-trans";
import ExchangeTrans from "./com/exchange-trans";
export default {
  name: "PostionSet",
  components: {
    AccountTrans,
    ExchangeTrans,
  },
  data() {
    return {
      active: 0,
    };
  },
};
</script>
